import { getUser } from './actions-user'
import { ChargingPointV1 } from '../models/charging-point-v1';
import { ConnectorV1 } from '../models/connector-v1';


export const canSeeChargingStatus = () => {
    const user = getUser()!
    return !!user.chargingId && !!user.connectorId;
}


export const getUserConnectors = (chargingPoint?: ChargingPointV1): Array<ConnectorV1> => {
    const user = getUser()!

    if (!canSeeChargingStatus()) {
        return []
    }

    if (!chargingPoint || !chargingPoint.connectors || chargingPoint.connectors.length === 0) {
        return []
    }

    // Can see both connectors. 0. Usually for RFID
    if (user.connectorId === '0') {
        if (chargingPoint.connectors.length === 1) {
            return [chargingPoint.connectors[1]]
        }
        return [
            chargingPoint.connectors[1],
            chargingPoint.connectors[2],
        ]
    } 

    const userConnectors = chargingPoint.connectors.filter((connector) => (connector.connectorId) === parseInt(user.connectorId ?? ''))

    if (userConnectors.length === 0) {
        return []
    }

    return [userConnectors[0]]
}

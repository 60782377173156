/** @format */

import { getStore } from "./store";
import { StatV1 } from "../models/stat-v1";

export const getTotalKwhForYear = (year: string): number => {
  const stats = getStore().getState().statsStore.stats;
  if (!stats || !stats[year]) {
    return 0;
  }

  let total = 0;
  Object.keys(stats[year]).forEach((monthKey) => {
    stats[year][monthKey].forEach((eachStat) => {
      // total += eachStat.meterStop - eachStat.meterStart

      const kwhConsumed =
        eachStat.meterStop - eachStat.meterStart < 0
          ? 0
          : eachStat.meterStop - eachStat.meterStart;
      total += kwhConsumed;
    });
  });
  return total / 1000.0;
};

/**
 * @param year is full year like 2020
 * @param month the month is from 0-11
 */
export const getTotalForMonth = (year: string, month: number): number => {
  const stats = getStore().getState().statsStore.stats;
  if (!stats || !stats[year]) {
    return 0;
  }

  let total = 0.0;
  Object.keys(stats[year]).forEach((monthKey) => {
    if (month == parseInt(monthKey)) {
      stats[year][monthKey].forEach((eachStat) => {
        // total += eachStat.meterStop - eachStat.meterStart;

        const kwhConsumed =
          eachStat.meterStop - eachStat.meterStart < 0
            ? 0
            : eachStat.meterStop - eachStat.meterStart;
        total += kwhConsumed;
      });
    }
  });
  if (total === 0) {
    return 0;
  }
  return total / 1000.0;
};

/**
 * @param year is full year like 2020
 * @param month the month is from 0-11
 *
 * Sorts them in order too
 */
export const getStatsForMonth = (
  year: string,
  month: number
): Array<StatV1> => {
  const stats = getStore().getState().statsStore.stats;
  if (!stats || !stats[year]) {
    return [];
  }

  let monthStats: Array<StatV1> = [];
  Object.keys(stats[year]).forEach((monthKey) => {
    if (month == parseInt(monthKey)) {
      stats[year][monthKey].forEach((eachStat) => {
        monthStats.push(eachStat);
      });
    }
  });

  monthStats = monthStats.sort((a, b) =>
    a.startDate.localeCompare(b.startDate)
  );
  return monthStats;
};

/** @format */

export const no = {
  // Common words
  off: "av",
  on: "på",

  // Links for URLs
  urlLogin: "/",
  urlYourStats: "/ditt-forbruk",

  // Page titles
  pageTitleLogin: "Movel: Min-side Logg Inn",
  pageTitleYourStats: "Movel: Min-side Forbruk",

  // Login page
  loginBtn: "Pålogging",
  emailLabel: "E-post",
  passwordLabel: "Passord",
  rememberMe: "Husk meg",
  errorEmailInvalid: "Denne e-postadressen er ugyldig",
  errorPasswordInvalid: "Passord er ugyldig",
  passwordReset:
    "Passordet var feil. En e-post har blitt sendt for å resette den.",
  userNotFound: "Ingen bruker med denne e-postadressen finnes.",

  // Your consumption page
  yourConsumption: "Ditt forbruk",
  mySettings: "Mine innstillinger",
  lock: "Kabellås",
  lockHint: "Kabellås er {status}",
  totalForYear: "Totalt {year}",
  totalKwhForYear: "{number} kWh",
  fetchingStats: "Henter forbruksstatistikk",
  chargingId: "Lade ID: {chargingId}",
  idTag: "Ladebrikke: {idTag}",
  connectorId: "Uttak nr: {connectorId}",
  chargingStatus: "Status: {status}",
  monthOverview: "Månedlig oversikt",
  totalKwhForMonth: "{number} kWh",
  acceptCookies:
    "For å gi deg en bedre opplevelse bruker Movel informasjonskapsler.",

  // Charging status (linked to api)
  Available: "Klar til lading",
  Charging: "Lading pågår",
  Preparing: "Forbereder",
  SuspendedEV: "Ferdig ladet",
  SuspendedEVSE: "Ferdig ladet",
  SuspendedCAR: "Ferdig ladet",
  SuspendedCHARGER: "Ferdig ladet",
  Finnishing: "Ferdig ladet",
  Finishing: "Finishing",
  Offline: "Frakoblet",
  Error: "Feil",
  Reserved: "Reservert",
  Info: "Informasjon",
  Unavailable: "Utilgjengelig",

  // Months of the year
  monthsShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mai",
    "Juni",
    "Juli",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Des",
  ],
  monthsLong: [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember",
  ],
};

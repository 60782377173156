import { StatV1 } from "../models/stat-v1"

export const StatsActions = {
    init: 'stats-init',
    gotStats: 'got-stats',
    loggedOut: 'stats-logged-out',
    setCurrentYear: 'set-year',
    setIsLoadingStats: 'set-is-loading-stats',
}

export type StatsState = {
    isLoadingStats: boolean,
    // Year in string like 2020, string because it's the key of the stats.
    currentYear?: string,
    // The year is full year like 2020, the month is full month from 0-11
    stats?: { [year: string]: { [month: string]: [StatV1] } },
}

const initialState = {
    isLoadingStats: false,
}

export const statsReducers = (state: StatsState = initialState, action: any): StatsState => {
    switch (action.type) {
        case StatsActions.setIsLoadingStats: {
            return {
                ...state,
                isLoadingStats: action.data
            }
        }
        case StatsActions.setCurrentYear: {
            return {
                ...state,
                currentYear: action.data
            }
        }
        case StatsActions.init: {
            return {
                ...state,
                ...initialState,
            }
        }
        case StatsActions.gotStats: {
            return {
                ...state,
                stats: action.data,
            }
        }
        case StatsActions.loggedOut: {
            return initialState
        }
        default: {
            return state
        }
    }
}
import React, { useEffect } from 'react';
import { TextField, Checkbox, Button } from '@material-ui/core';
import { lang } from '../translations/user_langauge';
import { Row } from '../common/row';
import { Expanded } from '../common/expanded';
import { login, passwordReset, isLoggedIn } from '../redux/actions-user';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundImage: 'url(/login/bg.jpg)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    movelTag: {
        width: 130,
        height: 54,
        backgroundImage: `url(/login/tag.png)`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        zIndex: 3,
    },
    centerLoginBox: {
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: 16,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginBottom: 64,
        maxWidth: 300,
        boxShadow: "0px 2px 4px #000000CC",
        borderTop: "5px solid #02242b",
    },
});

export const PageLogin = () => {

    const user = useSelector((state: RootState) => state.userStore.user)

    // Load stuff on this page
    useEffect(() => {
        if (user?.uid) {
            window.location.href = lang.urlYourStats
        }
    }, []);


    document.title = lang.pageTitleLogin;
    const classes = useStyles({});
    return (
        <div className={classes.root}>
            {/* Page padding */}
            <div style={{ display: 'flex', padding: 16, flexDirection: 'column', flex: 1 }}>
                <div className={classes.movelTag} />
                <LoginBox />
            </div>
        </div>
    )
}

type InputValues = {
    email: string,
    password: string,
    rememberMe: false,
    errorEmail?: string,
    errorPassword?: string,
}

const LoginBox = () => {

    const classes = useStyles({});

    const inputValues: InputValues = {
        email: '',
        password: '',
        rememberMe: false,
    }

    const [values, setValues] = React.useState(inputValues);

    const handleChange = (name: string) => (event: any) => {
        setValues({ ...values, [name]: event.target.value || event.target.checked });
    };

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        }}>
            <form className={classes.centerLoginBox}>
                <TextField
                    label={lang.emailLabel}
                    variant="filled"
                    type="email"
                    onChange={handleChange('email')}
                    error={!!values.errorEmail}
                    helperText={values.errorEmail}
                />
                <div style={{ height: 24 }} />
                <TextField
                    label={lang.passwordLabel}
                    variant="filled"
                    type="password"
                    onChange={handleChange('password')}
                    error={!!values.errorPassword}
                    helperText={values.errorPassword}
                />
                <div style={{ height: 12 }} />
                <Row>
                    <Checkbox
                        style={{ marginLeft: -8 }}
                        color="primary"
                        onChange={handleChange('rememberMe')}
                    />
                    <p>{lang.rememberMe}</p>
                    <Expanded />
                    <Button
                        color="primary"
                        onClick={() => {
                            loginAction(values, setValues);
                        }}
                    >{lang.loginBtn}
                    </Button>
                </Row>
            </form>
        </div>
    )
}

const loginAction = async (inputValues: InputValues, setValues: React.Dispatch<React.SetStateAction<InputValues>>) => {

    const errors: any = {};

    delete inputValues['errorEmail']
    delete inputValues['errorPassword']

    if (!inputValues.email) {
        errors.errorEmail = lang.errorEmailInvalid;
    }

    if (!inputValues.password) {
        errors.errorPassword = lang.errorPasswordInvalid;
    }

    setValues({
        ...errors,
        ...inputValues
    })

    // There are some errors, don't continue.
    if (Object.keys(errors).length > 0) {
        return
    }

    try {
        await login(inputValues.email, inputValues.password, inputValues.rememberMe)
    } catch (e) {

        if (e.code) {
            if (e.code === 'auth/wrong-password') {
                errors.errorPassword = lang.passwordReset
                passwordReset(inputValues.email)
            }
            if (e.code === 'auth/invalid-email') {
                errors.errorEmail = lang.errorEmailInvalid
            }
            if (e.code === 'auth/user-not-found') {
                errors.errorEmail = lang.userNotFound
            }
        }
    }

    setValues({
        ...errors,
        ...inputValues
    })
}
/** @format */

import firebase from "firebase";
import { StatV1 } from "../models/stat-v1";
import { Logger } from "../common/logger";
import { isLoggedIn, getUser } from "./actions-user";
import { getStore } from "./store";
import { StatsActions } from "./state-stats";

export const initStats = () => {
  if (isLoggedIn()) {
    fetchStats();
  }
};

export const setCurrentYear = async (year: string) => {
  getStore().dispatch({ type: StatsActions.setCurrentYear, data: year });
};

export const fetchStats = async () => {
  getStore().dispatch({ type: StatsActions.setIsLoadingStats, data: true });
  const user = getUser()!;

  try {
    let stats = (await firebase.functions().httpsCallable("getStatsV1")())
      .data as Array<StatV1>;

    // Process the stats

    // Is missing UTC & T stamp.
    stats = stats.map((s) => {
      s.startDate = s.startDate.replace(" ", "T");
      //s.startDate = s.startDate += "Z";
      s.endDate = s.endDate.replace(" ", "T");
      //s.endDate = s.endDate += "Z";
      return s;
    });

    // Filter by the users connector id.
    if (!user.idTag) {
      stats = stats.filter((s) => {
        if (!user.connectorId) {
          return true;
        }
        if (user.connectorId === 0 + "") {
          return true;
        }
        return s.connectorId === parseInt(user.connectorId ?? -1);
      });
    }

    //Old code based on startDate
    //   // Put them into their years and months.
    //   const processedStats: {
    //     [year: number]: { [month: number]: Array<StatV1> };
    //   } = {};
    //   stats.forEach((s) => {
    //     const startDate = new Date(s.startDate);
    //     if (!processedStats[startDate.getFullYear()]) {
    //       processedStats[startDate.getFullYear()] = {};
    //     }
    //     if (!processedStats[startDate.getFullYear()][startDate.getMonth()]) {
    //       processedStats[startDate.getFullYear()][startDate.getMonth()] = [];
    //     }
    //     processedStats[startDate.getFullYear()][startDate.getMonth()].push(s);
    //   });
    //   // Sort stats in each month by start date.
    //   // Each year
    //   Object.keys(processedStats).forEach((yearKey) => {
    //     // Each month
    //     Object.keys(processedStats[parseInt(yearKey)]).forEach((monthKey) => {
    //       const sortedMonths = processedStats[parseInt(yearKey)][
    //         parseInt(monthKey)
    //       ].sort((a, b) => a.startDate.localeCompare(b.startDate));
    //       processedStats[parseInt(yearKey)][parseInt(monthKey)] = sortedMonths;
    //     });
    //   });

    // Put them into their years and months.
    const processedStats: {
      [year: number]: { [month: number]: Array<StatV1> };
    } = {};
    stats.forEach((s) => {
      const endDate = new Date(s.endDate);
      if (!processedStats[endDate.getFullYear()]) {
        processedStats[endDate.getFullYear()] = {};
      }
      if (!processedStats[endDate.getFullYear()][endDate.getMonth()]) {
        processedStats[endDate.getFullYear()][endDate.getMonth()] = [];
      }
      processedStats[endDate.getFullYear()][endDate.getMonth()].push(s);
    });

    // Sort stats in each month by start date.
    // Each year
    Object.keys(processedStats).forEach((yearKey) => {
      // Each month
      Object.keys(processedStats[parseInt(yearKey)]).forEach((monthKey) => {
        const sortedMonths = processedStats[parseInt(yearKey)][
          parseInt(monthKey)
        ].sort((a, b) => a.endDate.localeCompare(b.endDate));
        processedStats[parseInt(yearKey)][parseInt(monthKey)] = sortedMonths;
      });
    });

    // Set the latest year from the stats.
    let latestYear = Object.keys(processedStats).sort(
      (a, b) => parseInt(b) - parseInt(a)
    );
    if (latestYear.length > 0) {
      getStore().dispatch({
        type: StatsActions.setCurrentYear,
        data: latestYear[0],
      });
    }

    Logger.log("Got stats");

    getStore().dispatch({ type: StatsActions.gotStats, data: processedStats });
    getStore().dispatch({ type: StatsActions.setIsLoadingStats, data: false });
  } catch (e) {
    getStore().dispatch({ type: StatsActions.setIsLoadingStats, data: false });
    Logger.error("There was an error retriving the stats", e);
  }
};

import { UserActions } from "./state-user"
import { Logger } from "../common/logger"
import { getStore, RootState, persistor } from "./store"
import firebase from 'firebase'
import { UserV1 } from "../models/user-v1"
import { Store } from "redux"
import { lang } from "../translations/user_langauge"
import { refreshData } from "./actions-common"

export const initUserStore = (store: Store<RootState>) => {
    store.dispatch({ type: UserActions.init })
    authListener()
}


let userListener: Function | undefined;
export const listenForUserChanges = () => {
    if (userListener) {
        userListener()
        userListener = undefined;
    }
    let firstRequest = true;
    if (isLoggedIn()) {
        userListener = firebase.firestore().collection('users').doc(getUser()!.uid).onSnapshot((snapshot) => {
            if (!firstRequest) {
                Logger.log('Got new user data')
                getStore().dispatch({ type: UserActions.gotUser, data: snapshot.data() })
                refreshData()
            }
            firstRequest = false;
        })
    }
}

export const login = async (email: string, password: string, rememberMe: boolean) => {

    getStore().dispatch({ type: UserActions.setLoadingOverlayActive, data: true })

    try {

        if (rememberMe) {
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        } else {
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        }

        const userCred = await firebase.auth().signInWithEmailAndPassword(email, password)

        await fetchUser(userCred.user?.uid)

        getStore().dispatch({ type: UserActions.setLoadingOverlayActive, data: false })

        Logger.log('Login success')
        window.location.href = lang.urlYourStats

    } catch (e) {
        getStore().dispatch({ type: UserActions.setLoadingOverlayActive, data: false })
        Logger.error('Error logging in', e)
        throw e;
    }
}

export const getUser = (): UserV1 | undefined => {
    if (getStore().getState().userStore) {
        return getStore().getState().userStore.user
    }
}

export const fetchUser = async (uid?: string): Promise<UserV1> => {

    try {
        let userUid = uid;
        if (uid == null) {
            const user = getStore().getState().userStore.user
            if (user) {
                userUid = user.uid;
            }
        }

        const doc = await firebase.firestore().collection('users').doc(userUid).get()
        const user = doc.data() as UserV1;

        getStore().dispatch({ type: UserActions.gotUser, data: user })
        Logger.log('Got user: ' + doc.id);

        return user;
    } catch (e) {
        Logger.error('Error fetching user', e)
        throw e
    }
}

export const authListener = () => {
    firebase.auth().onAuthStateChanged((user) => {
        if (!user) {
            if (isLoggedIn()) {
                Logger.log('No auth user, logging out.')
                logout(true)
            }
        }
    })
}

export const passwordReset = (email: string) => {
    firebase.auth().sendPasswordResetEmail(email)
}

export const logout = (redirect = false) => {
    Logger.log('Logging out')
    if (userListener) {
        userListener()
    }
    firebase.auth().signOut()
        .then(() => {
            return persistor.purge()
        })
        .then(() => {
            if (redirect) {
                window.location.href = '/'
            }
        })
        .catch((e) => {
            Logger.error('Error logging out', e)
        })
}

export const isLoggedIn = () => {
    if (getUser()) {
        return true
    }
    return false
}
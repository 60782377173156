import React from 'react'
import { grey } from '@material-ui/core/colors'

export const PageRoot = (props: any) => {
    return (
        <div style={{ ...{ flex: 1, flexDirection: 'column' }, ...props.style }}>
            {props.children}
        </div>
    )
}

export const Colors = {
    primary: '#02242b',
    secondary: grey[300],
    green: '#50c85a',
    fadedFont: '#8a8a8a',
    lightGreyBackground: '#efefef',
}
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core';
import { lang } from '../../translations/user_langauge';
import { getTotalForMonth } from '../../redux/helpers-stats';
import { Colors } from '../../common/styles';

const useStyles = makeStyles({
    chartContainer: {
        maxHeight: 500,
        maxWidth: 1000,
        paddingTop: 32,
        paddingBottom: 32,
        width: '100%',
        margin: '0 auto',
    }
})

export const StatsGraph = () => {

    const classes = useStyles({})
    const currentYear = useSelector((state: RootState) => state.statsStore.currentYear!)

    const data = {
        labels: lang.monthsShort,
        datasets: [{
            backgroundColor: Colors.primary,
            borderColor: Colors.primary,
            data: Array(12).fill(0).map(((i: number, index: number) => getTotalForMonth(currentYear, index)))
        }],
    };

    const options: Chart.ChartOptions = {
        legend: {
            display: false
        },
    }

    return (
        <div className={classes.chartContainer}>
            <Line data={data} options={options} />
        </div>
    )
}
/** @format */

export const en = {
  // Common words
  off: "off",
  on: "on",

  // Links for URLs
  urlLogin: "/",
  urlYourStats: "/your-consumption",

  // Page titles
  pageTitleLogin: "Movel: Min-side login",
  pageTitleYourStats: "Movel: Min-side consumption",

  // Login page
  loginBtn: "Login",
  emailLabel: "Email",
  passwordLabel: "Password",
  rememberMe: "Remember me",
  errorEmailInvalid: "The email address was invalid",
  errorPasswordInvalid: "The password was invalid",
  passwordReset:
    "Your password was incorrect. A password reset email has been sent to your email address.",
  userNotFound: "No user exists with this email address.",

  // Your consumption page
  yourConsumption: "Your consumption",
  mySettings: "My Settings",
  lock: "Lock",
  lockHint: "Pernament cable lock is {status}",
  totalForYear: "Total for {year}",
  totalKwhForYear: "{number} kWh",
  fetchingStats: "Fetching charging stats",
  chargingId: "Charging ID: {chargingId}",
  idTag: "ID Tag: {idTag}",
  connectorId: "Connector Id: {connectorId}",
  chargingStatus: "Status: {status}",
  monthOverview: "Month overview",
  totalKwhForMonth: "{number} kWh",
  acceptCookies: "Movel uses cookies for functional and analytical purposes.",

  // Charging status (linked to api)
  Available: "Available",
  Charging: "Charging",
  Preparing: "Preparing",
  SuspendedEV: "Finished Charging",
  SuspendedEVSE: "Finished Charging",
  SuspendedCAR: "Finished Charging",
  SuspendedCHARGER: "Finished Charging",
  Finnishing: "Finishing",
  Finishing: "Finishing",
  Error: "Error",
  Offline: "Offline",
  Reserved: "Reserved",
  Info: "Info",
  Unavailable: "Unavailable",

  // Months of the year
  monthsShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ],
  monthsLong: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};

/** @format */

import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { lang } from "../translations/user_langauge";
import {
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Switch,
  FormControlLabel,
  CircularProgress,
  Button,
  Grid,
  Box,
  Link,
} from "@material-ui/core";
import Settings from "@material-ui/icons/Settings";
import LocalPhone from "@material-ui/icons/LocalPhone";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { Row } from "./row";
import { Colors } from "./styles";
import { logout } from "../redux/actions-user";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setCurrentYear } from "../redux/actions-stats";
import { switchLockStatus } from "../redux/actions-status";
import { ChargingPointV1 } from "../models/charging-point-v1";
import {
  getUserConnectors,
  canSeeChargingStatus,
} from "../redux/helpers-status";
import { ConnectorV1 } from "../models/connector-v1";
import { ConfigurationV1 } from "../models/configuation-v1";
import { Column } from "./column";
import { Logger } from "./logger";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbarSpacer: {
      height: 64,
    },
    title: {
      fontSize: 18,
      "@media (max-width: 600px)": {
        fontSize: 16,
      },
    },
    yearSelected: {
      color: "#50c85a",
      borderBottom: "2px solid #50c85a",
      fontWeight: 600,
      fontSize: 18,
    },
    selectYear: {
      fontSize: 16,
      fontWeight: 500,
      padding: 16,
      "@media (max-width: 600px)": {
        fontSize: 20,
        padding: 18,
      },
    },
  })
);

export const MainAppBar = () => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="secondary" elevation={1}>
        <Toolbar>
          <LeftMenu />
          <RightIcons />
        </Toolbar>
      </AppBar>
      <div className={classes.toolbarSpacer} />
    </div>
  );
};

const LeftMenu = () => {
  const classes = useStyles({});

  const statsStore = useSelector((state: RootState) => state.statsStore);
  const isLoadingStats = useSelector(
    (state: RootState) => state.statsStore.isLoadingStats
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let currentYears = Object.keys(statsStore.stats || {}).map((yearKey) => {
    return (
      <MenuItem
        className={classes.selectYear}
        key={yearKey}
        onClick={() => {
          setCurrentYear(yearKey);
          handleClose();
        }}
      >
        {yearKey}
      </MenuItem>
    );
  });
  currentYears = currentYears.reverse();

  return (
    <Row flexOverride={{ alignItems: "center", flex: 1 }}>
      <Typography variant="h6" className={classes.title}>
        {lang.yourConsumption}
      </Typography>
      <div>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {!isLoadingStats && (
            <MenuItem className={classes.yearSelected}>
              {statsStore.currentYear}
            </MenuItem>
          )}
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          {currentYears}
        </Menu>
      </div>
    </Row>
  );
};

const RightIcons = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Row flexOverride={{ alignItems: "center" }}>
      <a href="https://www.movel.no/support" target="_blank">
          <IconButton
            color="inherit"
            title="Hjelp senter"
          >
            <LiveHelpIcon color="primary" />
          </IconButton>
        </a>
      <PhoneNumber />
      <IconButton
        aria-label="Settings of the current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Settings />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuOptions />
      </Menu>
    </Row>
  );
};

const PhoneNumber = () => {
  return (
    <a href="tel:+4747504041">
      <IconButton
        aria-label="Phone customer support"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
      >
        <LocalPhone color="primary" />
      </IconButton>
    </a>
  );
};

// TODO Cable locks, multiple ones.
const MenuOptions = () => {
  return (
    <div style={{ padding: 16 }}>
      <Typography variant="h6">{lang.mySettings}</Typography>
      <Divider style={{ marginBottom: 8, marginTop: 12 }} />
      <SwitchLocks />
      <MenuItem style={{ paddingLeft: 0 }} onClick={() => logout(true)}>
        Logout
      </MenuItem>
    </div>
  );
};

const SwitchLocks = () => {
  const lockConfigs = useSelector(
    (state: RootState) => state.statusStore.lockConfiguration
  )!;
  const chargingPoint = useSelector(
    (state: RootState) => state.statusStore.userChargingPoint!
  );

  if (!lockConfigs) {
    return <div />;
  }

  if (!canSeeChargingStatus()) {
    return <div />;
  }

  const userConnectors = getUserConnectors(chargingPoint);

  if (userConnectors.length === 0) {
    return <div />;
  }

  // Create an array of switch locks
  const switchLocks = userConnectors.map((connector) => {
    return <ButtonLock chargingPoint={chargingPoint} connector={connector} />;
  });

  return <Column>{switchLocks}</Column>;
};

type SwitchLockProps = {
  chargingPoint: ChargingPointV1;
  connector: ConnectorV1;
};

const ButtonLock = (props: SwitchLockProps) => {
  const lockConfigs = useSelector(
    (state: RootState) => state.statusStore.lockConfiguration
  )!;

  let lockConfig: ConfigurationV1;
    
  if (props.connector.connectorId == 1) {
    if(lockConfigs["LockPermanently"]){
      lockConfig = lockConfigs["LockPermanently"]
    }else if(lockConfigs["outlet/1/keep_cable_locked"]){
      lockConfig =lockConfigs["outlet/1/keep_cable_locked"]
    } else{lockConfig =lockConfigs["LockCablePermanently"]};
  } else{
   lockConfig = lockConfigs["LockPermanently_2"];
  }


  const isLocked: boolean = (lockConfig?.value ?? "0") == "1"||(lockConfig?.value ?? "false") == "true";
  const [isLockedLocal, setIsLockedLocal] = useState(isLocked);
  const isFetchingStatus = useSelector(
    (state: RootState) => state.statusStore.isFetchingCableStatus
  );

  const alertMessage: string = "Låse/Låse opp kabel kan ta rundt ett minutt." //Laderen blinker i løpet av tiden. Etter at lyset blir grønt, vil den nye kabelstatusen oppdateres ved å oppdatere Minside.
  
  const sendAlertMessage = (config:ConfigurationV1)=>{
    if(config.key ==="outlet/1/keep_cable_locked")
    alert(alertMessage);
  };

  return (
    <div>
      {!isFetchingStatus && (
        <Box mb={2} mt={1} textAlign="center">
          <Grid direction="row" spacing={2} alignContent="center">
            <Button
              variant="contained"
              color="primary"
              style={{
                fontSize: 10,
                width: 60,
                marginRight: 4,
                color: Colors.fadedFont,
                textTransform: "capitalize",
              }}
              onClick={(event: any) => {
                setIsLockedLocal(false);
                sendAlertMessage(lockConfig);
                switchLockStatus(lockConfig, "toOff").catch((e) => {
                  setIsLockedLocal(true);
                });
              
              }}
            >
              {lang.on}
            </Button>
            <Button
              color="primary"
              variant="contained"
              style={{
                width: 60,
                fontSize: 10,
                color: Colors.fadedFont,
                textTransform: "capitalize",
              }}
              onClick={(event: any) => {
                setIsLockedLocal(true);
                sendAlertMessage(lockConfig);
                switchLockStatus(lockConfig, "toOn").catch((e) => {
                  setIsLockedLocal(false); 
                });
              }}
            >
              {lang.off}
            </Button>
          </Grid>
        </Box>
      )}
      {!isFetchingStatus && (
        <Typography
          style={{ fontSize: 11, color: Colors.fadedFont, textAlign: "center" }}
        >
          {lang.lockHint.replace("{status}", isLocked ? lang.on : lang.off)}
        </Typography>
      )}
      {isFetchingStatus && <CircularProgress style={{ alignSelf: "center" }} />}
      <Divider style={{ marginBottom: 8, marginTop: 8 }} />
    </div>
  );
};

// From original code, it seems that SwitchLock function is not used. It switches on/off by switching but not clicking
const SwitchLock = (props: SwitchLockProps) => {
  const lockConfigs = useSelector(
    (state: RootState) => state.statusStore.lockConfiguration
  )!;

  let lockConfig: ConfigurationV1;

  if (props.connector.connectorId == 1) {
    lockConfig = lockConfigs["LockPermanently"];
  } else {
    lockConfig = lockConfigs["LockPermanently_2"];
  }

  const isLocked: boolean = (lockConfig?.value ?? "1") == "0";
  const [isLockedLocal, setIsLockedLocal] = useState(isLocked);
  const isFetchingStatus = useSelector(
    (state: RootState) => state.statusStore.isFetchingCableStatus
  );
  

  return (
    <div>
      {!isFetchingStatus && (
        <FormControlLabel
          control={
            <Switch
              checked={isLockedLocal}
              color="primary"
              value="checked"
              onClick={(event: any) => {
                setIsLockedLocal(!isLocked);
                switchLockStatus(lockConfig).catch((e) => {
                  setIsLockedLocal(isLocked);
                });
              }}
            />
          }
          label={lang.lock}
        />
      )}
      {!isFetchingStatus && (
        <Typography style={{ fontSize: 11, color: Colors.fadedFont }}>
          {lang.lockHint.replace("{status}", isLocked ? lang.on : lang.off)}
        </Typography>
      )}
      {isFetchingStatus && <CircularProgress style={{ alignSelf: "center" }} />}
      <Divider style={{ marginBottom: 8, marginTop: 8 }} />
    </div>
  );
};

import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { MovelLoading } from './movel-loading';

const useStyles = makeStyles({
    root: {
        background: '#50c85a',
        backgroundColor: '#50c85a',
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'center',
    },
});

export const LoadingOverlay = () => {
    const classes = useStyles({});
    const isActive = useSelector((state: RootState) => state.userStore.isLoadingOverlayActive)

    if (!isActive) {
        return null
    }

    return (
        <div className={classes.root}>
            <div style={{ marginBottom: 100 }}>
                <MovelLoading />
            </div>
        </div>
    )
}
import React from 'react';
import { Column } from '../../common/column'
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { lang } from '../../translations/user_langauge';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getTotalKwhForYear } from '../../redux/helpers-stats';
import { canSeeChargingStatus, getUserConnectors } from '../../redux/helpers-status';

const useStyles = makeStyles({
    root: {
        backgroundColor: '#50c85a',
        padding: '40px 20px',
    },
    overviewFont: {
        color: '#fff',
        fontWeight: 500,
        lineHeight: 1.2,
        textShadow: '0.5px 1px 1px rgba(0,0,0,.5)',
        fontSize: 20,
        marginBottom: 4,
    },
})


export const Overview = () => {
    const classes = useStyles({})
    const statsState = useSelector((state: RootState) => state.statsStore)
    const user = useSelector((state: RootState) => state.userStore.user!)

    return (
        <Column className={classes.root}>
            {/* Title */}
            <Typography
                className={classes.overviewFont}
                style={{ fontSize: 18 }}>{lang.totalForYear.replace('{year}', statsState.currentYear ?? '')}
            </Typography>
            {/* Total for year */}
            <Typography
                className={classes.overviewFont}
                style={{ fontSize: 46, fontWeight: 600 }}>{lang.totalKwhForYear.replace('{number}', getTotalKwhForYear(statsState.currentYear ?? '').toFixed(0))}
            </Typography>
            {/* Charging id */}
            {!!user.chargingId && <Typography
                className={classes.overviewFont}
                style={{ fontSize: 18 }}>{lang.chargingId.replace('{chargingId}', user.chargingId ?? '')}
            </Typography>}
            {/* RF-ID-Tag */}
            {!!user.idTag && <Typography
                className={classes.overviewFont}
                style={{ fontSize: 18 }}>{lang.idTag.replace('{idTag}', user.idTag ?? '')}
            </Typography>}
            <ChargingStatus />
        </Column>
    )
}

/**
 * The status is calculated from a few different things.
 * 
 * Users can have access to all connectorIds.
 * Users can have access to 1.
 * TODO Users can have access to an RFID which has multiple stations and connectors.
 * Not sure how to do RFID one.
 */
const ChargingStatus = () => {

    const classes = useStyles({})

    const chargingPoint = useSelector((state: RootState) => state.statusStore.userChargingPoint!)

    if (!canSeeChargingStatus()) {
        return (<div />)
    }

    const userConnectors = getUserConnectors(chargingPoint)

    if (userConnectors.length === 0) {
        return (<div />)
    }

    // Create an array of statuses
    const chargingStatuses = userConnectors.map((connector) => {
        let chargingStatus = lang.chargingStatus.replace('{number}', (connector.connectorId + 1).toString())
        chargingStatus = chargingStatus.replace('{status}', lang[connector.status])
        return (
            <Typography
                key={connector.connectorId}
                className={classes.overviewFont}
                style={{ fontSize: 18 }}>{chargingStatus}
            </Typography>
        )
    })

    return (
        <Column>
            {chargingStatuses}
        </Column>
    )
}
import { UserV1 } from "../models/user-v1";

export const UserActions = {
    init: 'user-init',
    loggedOut: 'user-logged-out',
    gotUser: 'got-user',
    setLoadingOverlayActive: 'set-loading-overlay-active',
}

export type UserState = {
    user?: UserV1,
    isLoadingOverlayActive: boolean,
}

const initialState = {
    isLoadingOverlayActive: false,
}

export const userReducers = (state: UserState = initialState, action: any): UserState => {
    switch (action.type) {
        case UserActions.setLoadingOverlayActive: {
            return {
                ...state,
                isLoadingOverlayActive: action.data
            }
        }
        case UserActions.init: {
            return {
                ...state,
                ...initialState,
            }
        }
        case UserActions.gotUser: {
            return {
                ...state,
                user: action.data,
            }
        }
        case UserActions.loggedOut: {
            return initialState
        }
        default: {
            return state
        }
    }
}
/** @format */

import React from "react";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Theme,
  Divider,
} from "@material-ui/core";
import { lang } from "../../translations/user_langauge";
import { makeStyles, createStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { getTotalForMonth, getStatsForMonth } from "../../redux/helpers-stats";
import { RootState } from "../../redux/store";
import { Colors } from "../../common/styles";
import { StatV1 } from "../../models/stat-v1";
import { Column } from "../../common/column";
import { Row } from "../../common/row";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 16,
    },
  })
);

export const ListOfMonthStats = () => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Typography variant="h6">{lang.monthOverview}</Typography>
      <div style={{ height: 16 }} />
      <MonthExpansionPanels />
    </div>
  );
};

export const MonthExpansionPanels = () => {
  const currentYear = useSelector(
    (state: RootState) => state.statsStore.currentYear!
  );

  let monthsData = Array(12)
    .fill(0)
    .map((i: number, index: number) => getTotalForMonth(currentYear, index));

  const monthExpansionPanels = monthsData.map((m, index) => {
    return expansionPanel(currentYear, index);
  });

  return <div>{monthExpansionPanels}</div>;
};

export const expansionPanel = (year: string, month: number) => {
  const totalKwhMonth = getTotalForMonth(year, month);
  const statsForMonthc = getStatsForMonth(year, month);

  if (statsForMonthc.length === 0) {
    return <div key={year + "_" + month + "div"} />;
  }

  const statLines: any = statsForMonthc.map((sm, index) => {
    return (
      <Column
        key={index + "_key_" + year + "_" + month}
        flexOverride={{ paddingTop: 6 }}
      >
        {statLine(sm)}
        {index != statsForMonthc.length - 1 && (
          <Divider style={{ marginTop: 8, marginBottom: 8 }} />
        )}
      </Column>
    );
  });

  return (
    <ExpansionPanel key={year + "_" + month}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontWeight: 600, minWidth: 120, marginRight: 20 }}>
          {lang.monthsLong[month]}
        </Typography>
        <Typography style={{ color: Colors.green, fontWeight: 600 }}>
          {lang.totalKwhForMonth.replace("{number}", totalKwhMonth.toFixed(2))}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ backgroundColor: Colors.secondary }}>
        <Column flexOverride={{ flex: 1 }}>{statLines}</Column>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export const statLine = (stat: StatV1) => {
  const startDate = new Date(stat.startDate);
  const endDate = new Date(stat.endDate);

  const monthsNum = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  let isStartDateNumber =
    startDate.getDate() === 1 ||
    startDate.getDate() === 2 ||
    startDate.getDate() === 3 ||
    startDate.getDate() === 4 ||
    startDate.getDate() === 5 ||
    startDate.getDate() === 6 ||
    startDate.getDate() === 7 ||
    startDate.getDate() === 8 ||
    startDate.getDate() === 9;

  let isEndDateNumber =
    endDate.getDate() === 1 ||
    endDate.getDate() === 2 ||
    endDate.getDate() === 3 ||
    endDate.getDate() === 4 ||
    endDate.getDate() === 5 ||
    endDate.getDate() === 6 ||
    endDate.getDate() === 7 ||
    endDate.getDate() === 8 ||
    endDate.getDate() === 9;

  let startDateModified =
    isStartDateNumber === true
      ? "0" + startDate.getDate()
      : startDate.getDate();
  let endDateModified =
    isEndDateNumber === true ? "0" + endDate.getDate() : endDate.getDate();

  return (
    <Row
      key={stat.connectorId + "_" + stat.startDate}
      flexOverride={{ alignItems: "center" }}
    >
      <Column flexOverride={{ flex: 1 }}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {startDate.getFullYear() +
            "." +
            monthsNum[startDate.getMonth()] +
            "." +
            startDateModified +
            "  " +
            startDate.toLocaleTimeString()}
        </Typography>

        <Typography
          style={{
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {endDate.getFullYear() +
            "." +
            monthsNum[endDate.getMonth()] +
            "." +
            endDateModified +
            "  " +
            endDate.toLocaleTimeString()}
        </Typography>
      </Column>
      <Typography
        style={{ color: Colors.green, fontWeight: 500, fontSize: 18 }}
      >
        {lang.totalKwhForMonth.replace(
          "{number}",
          ((stat.meterStop - stat.meterStart) / 1000).toFixed(2)
        )}
      </Typography>
    </Row>
  );
};

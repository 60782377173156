import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

type MovelLoadingProps = {
    darkTheme?: boolean,
}

const useStyles = makeStyles({
    root: {
        zIndex: 999,
        width: 200,
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingSpinner: {
        colorPrimary: '#fff'
    }
});

export const MovelLoading = (props: MovelLoadingProps) => {
    const classes = useStyles({})
    return (
        <div className={classes.root}>
            <div style={{
                background: props.darkTheme ? 'url(/movel_dark.png)' : 'url(/movel_light.png)',
                width: 200,
                height: 200,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '140px 140px',
                zIndex: 1010,
            }} >
                <CircularProgress
                    className={classes.loadingSpinner}
                    size={200}
                    color='primary'
                    thickness={2.6}
                />
            </div>
        </div>
    )
}
import React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type Props = {
    children?: any,
    className?: any,
    flexOverride?: CSSProperties
}

export const Column = (props: Props) => {

    const style: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        ...props.flexOverride
    }

    return (
        <div
            className={props.className}
            style={style}
        >
            {props.children}
        </div>
    )
}
/** @format */

import { isLoggedIn, getUser } from "./actions-user";
import { Logger } from "../common/logger";
import { functions } from "firebase";
import { ChargingPointV1 } from "../models/charging-point-v1";
import { getStore } from "./store";
import { StatusActions } from "./state-status";
import { ConnectorV1 } from "../models/connector-v1";
import { ConfigurationV1 } from "../models/configuation-v1";

export const initStatus = async () => {
  if (isLoggedIn()) {
    fetchChargingPoint();
    fetchCableStatus();
  }
};

export const fetchChargingPoint = async () => {
  try {
    const status: ChargingPointV1 = (
      await functions().httpsCallable("getChargingStatusV1")() // 
    ).data;
    getStore().dispatch({ type: StatusActions.gotChargingPoint, data: status });
  } catch (e) {
    Logger.error("Error fetching the status", e);
    throw e;
  }
};

//getUser() function returns a valid user object, the function checks whether the user object has both chargingId and connectorId properties
//If both properties are present and truthy, the function returns true, indicating that the user can control the cable status. Otherwise, the function returns false.
export const canControlCableStatus = (): boolean => {
  const user = getUser()!;
  return !!user.chargingId && !!user.connectorId; 
};

export const fetchCableStatus = async () => {
  getStore().dispatch({
    type: StatusActions.setIsFetchingCableStatus,
    data: true,
  });

  try {
    if (!canControlCableStatus()) {
      throw "User has no chargingId or connectorId, cannot fetch cable status";
    }
    //Assuming the user can control the cable status, 
    //the function then makes an HTTP call to a Cloud Function using the functions().httpsCallable() method, which is provided by the Firebase SDK
    //The getCabelStatusV1 function is in the backend "movel-firebase/functions/src/on-calls/get-cable-status" and returns an array of ConfigurationV1 objects.
    const chargingConfiguation: Array<ConfigurationV1> = (
      await functions().httpsCallable("getCabelStatusV1")()
    ).data;

    const lockConfiguations: { [key: string]: ConfigurationV1 } = {};

    chargingConfiguation.forEach((configuation: ConfigurationV1) => {
      if (
        configuation.key === "LockPermanently" ||
        configuation.key === "LockPermanently_2" || 
        configuation.key === "outlet/1/keep_cable_locked" || 
        configuation.key === "LockCablePermanently"
      ) {
        lockConfiguations[configuation.key] = configuation; //the lockConfiguations object contains only the configurations that have the "LockPermanently" or "LockPermanently_2" or "LockCablePermanently" or "outlet/1/keep_cable_locked" key values.
      }
    });

    getStore().dispatch({
      type: StatusActions.gotLockConfiguations,
      data: lockConfiguations,
    });
    getStore().dispatch({
      type: StatusActions.setIsFetchingCableStatus,
      data: false,
    }); //This action is dispatched to notify the store that the fetching of cable status is complete.
  } catch (e) {
    getStore().dispatch({
      type: StatusActions.setIsFetchingCableStatus,
      data: false,
    });
    Logger.error("Error fetching the status", e);
    throw e;
  }
};

export const switchLockStatus = async (
  lockConfig: ConfigurationV1,
  action?: string
) => {
  try {
    const newConfig: any = {};

    if (lockConfig.key==="LockPermanently"||lockConfig.key==="LockPermanently_2") { // try: if (action === "toOff") {if(lockConfig.key==="LockPermanently"||lockConfig.key==="LockPermanently_2"){newConfig[lockConfig.key + ""] = lockConfig.value = "1";}}
      if (action === "toOff") {
        newConfig[lockConfig.key + ""] = lockConfig.value = "1"; 
      } else if (action === "toOn") {
        newConfig[lockConfig.key + ""] = lockConfig.value = "0"; 
      } else {
        newConfig[lockConfig.key + ""] = lockConfig.value == "0" ? "1" : "0";
      }
    } else {//if (lockConfig.key==="LockCablePermanently"||lockConfig.key==="outlet/1/keep_cable_locked")
      if (action === "toOff") {
        newConfig[lockConfig.key + ""] = lockConfig.value = "true"; 
      } else if (action === "toOn") {
        newConfig[lockConfig.key + ""] = lockConfig.value = "false"; 
      } else {
        newConfig[lockConfig.key + ""] = lockConfig.value == "false" ? "true" : "false";
      }
    }

    await functions().httpsCallable("cabelSwichV1")(newConfig);
    await fetchCableStatus();
    await functions().httpsCallable("rebootChargerV1")(newConfig);
  } catch (e) {
    Logger.error("Error switching lock", e);
    throw e;
  }
};

import React from 'react';
import './App.css';
import { lang } from './translations/user_langauge'
import {
  BrowserRouter,
  Route,
  Redirect,
} from "react-router-dom";
import { PageLogin } from './pages/page-login';
import { en } from './translations/en';
import { no } from './translations/no';
import { loadStore, getStore, RootState } from './redux/store';
import firebase from 'firebase';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { LoadingOverlay } from './common/loading-overlay'
import { Provider, useSelector } from 'react-redux'
import { PageYourStats } from './pages/page-your-stats/page-your-stats';
import { Logger } from './common/logger';
import { isLoggedIn } from './redux/actions-user';
import { Colors } from './common/styles';

// Firebase
const config = {
  apiKey: "AIzaSyCBKuSk-5GbwXVVyvbYjGhfelo-BmynHV0",
  authDomain: "new-min-side.firebaseapp.com",
  databaseURL: "https://new-min-side-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "new-min-side",
  storageBucket: "new-min-side.appspot.com",
  messagingSenderId: "78749040297",
  appId: "1:78749040297:web:d79d9d39a4a0e55cd473b8",
  measurementId: "G-JEN7FX9BCS"
};
firebase.initializeApp(config);
firebase.analytics();

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
});

loadStore((store) => { })

// The main app.
const App: React.FC = () => {
  return (
    <Provider store={getStore()}>
      <Routes />
    </Provider>
  );
}

const Routes = () => {

  const isLoadedReduxPersist = useSelector((state: RootState) => !!state?._persist.rehydrated)
  if (!isLoadedReduxPersist) {
    return (<span>...</span>)
  }

  return (
    <ThemeProvider theme={theme}>
      <Paths />
      <LoadingOverlay />
    </ThemeProvider>
  )
}

const Paths = () => {
  if (isLoggedIn()) {
    return (
      <BrowserRouter>
        <Route exact path={['/']} component={PageLogin} />
        <Route exact path={[lang.urlYourStats, en.urlYourStats, no.urlYourStats]} component={PageYourStats} />
      </BrowserRouter>
    )
  } else {
    Logger.log('Not logged in')
    return (
      <BrowserRouter>
        <Route exact path={['/']} component={PageLogin} />
        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
      </BrowserRouter>
    )
  }
}

export default App;

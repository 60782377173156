import React, { useEffect } from 'react'
import { lang } from '../../translations/user_langauge';
import { PageRoot } from '../../common/styles';
import { MainAppBar } from '../../common/main-app-bar';
import { StatsGraph } from './stats-graph';
import { Overview } from './overview';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { makeStyles } from '@material-ui/styles';
import { MovelLoading } from '../../common/movel-loading';
import { Typography, Divider } from '@material-ui/core';
import { ListOfMonthStats } from './list-of-month-stats'
// @ts-ignore
import MUICookieConsent from 'material-ui-cookie-consent';
import { refreshData } from '../../redux/actions-common'
import { listenForUserChanges } from '../../redux/actions-user';

const useStyles = makeStyles({
    movelLoading: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 64,
    }
})

export const PageYourStats = () => {

    // Load stuff on this page
    useEffect(() => {
        listenForUserChanges()
        refreshData()
    }, []);

    document.title = lang.pageTitleYourStats;
    const classes = useStyles({})

    const isLoadingStats = useSelector((state: RootState) => state.statsStore.isLoadingStats)

    return (
        <PageRoot>
            <div>
                <MainAppBar />
            </div>
            {!isLoadingStats && <Overview />}
            {!isLoadingStats && <StatsGraph />}
            {!isLoadingStats && <Divider style={{ margin: 16 }} />}
            {!isLoadingStats && <ListOfMonthStats />}
            {!isLoadingStats && <div style={{ height: 120 }} />}
            {isLoadingStats && <div className={classes.movelLoading}>
                {<MovelLoading darkTheme={true} />}
                <div style={{ height: 32 }} />
                <Typography variant="h6">{lang.fetchingStats}</Typography>
            </div>}
            <MUICookieConsent
                cookieName="accept-our-terms"
                componentType="Snackbar"
                message={lang.acceptCookies}
            />
        </PageRoot>
    )
}

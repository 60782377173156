import { ChargingPointV1 } from "../models/charging-point-v1"
import { ConfigurationV1 } from '../models/configuation-v1'

export const StatusActions = {
    init: 'status-init',
    gotChargingPoint: 'got-status',
    loggedOut: 'status-logged-out',
    gotLockConfiguations: 'got-lock-configs',
    setIsFetchingCableStatus: 'set-is-fetching-cable-status',
}

export type StatusState = {
    userChargingPoint?: ChargingPointV1,
    // For "LockPermanently" & "LockPermanently_2" configuations. The key is same as the configuation.
    lockConfiguration?: { [key: string]: ConfigurationV1 },
    isFetchingCableStatus: boolean,
}

const initialState = {
    isFetchingCableStatus: false,
}

export const statusReducer = (state: StatusState = initialState, action: any): StatusState => {
    switch (action.type) {
        case StatusActions.init: {
            return {
                ...state,
                ...initialState,
            }
        }
        case StatusActions.gotLockConfiguations: {
            return {
                ...state,
                lockConfiguration: action.data,
            }
        }
        case StatusActions.setIsFetchingCableStatus: {
            return {
                ...state,
                isFetchingCableStatus: action.data,
            }
        }
        case StatusActions.gotChargingPoint: {
            return {
                ...state,
                userChargingPoint: action.data,
            }
        }
        case StatusActions.loggedOut: {
            return initialState
        }
        default: {
            return state
        }
    }
}